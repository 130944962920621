import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  userRoles,
  userRoleLabels,
  languages,
  languageLabels,
  allowedAdminRoleEmailPatterns,
} from '@models/user';
import { UntypedFormGroup } from '@angular/forms';
import { ElementId } from '@storykit/constants';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent {
  @Input() form!: UntypedFormGroup;
  @Input() verified: boolean | undefined;
  @Output() resendInviteEmail = new EventEmitter();

  userRoles = userRoles;
  userRoleLabels = userRoleLabels;

  elementId = ElementId;

  languages = languages;
  languageLabels = languageLabels;

  sectionOpenState = false;

  get adminEmailPatterns(): string {
    return allowedAdminRoleEmailPatterns.join(', ');
  }

  toggleSection() {
    this.sectionOpenState = !this.sectionOpenState;
  }

  resendEmail() {
    this.resendInviteEmail.emit();
  }
}
