import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { AgencyService } from '@core/services/agency.service';
import { Agency, CustomerType } from '@models/agency';
import { AgencyListQuery } from '@models/agency-filter';

const daysToInform = 7;
const monthsToDelete = 6;
const halfYearAgo = new Date();
halfYearAgo.setMonth(halfYearAgo.getMonth() - monthsToDelete);
const notifPeriod = new Date(halfYearAgo);
notifPeriod.setDate(notifPeriod.getDate() + daysToInform);
const notifPeriodTime = notifPeriod.getTime();

@Injectable()
export class AgencyListDataSource extends DataSource<Agency> {
  filters$: Observable<Partial<AgencyListQuery>>;
  deletionCandidates$ = new BehaviorSubject<Agency[]>([]);
  totalCount$: Observable<number>;

  private filterSubject = new BehaviorSubject<Partial<AgencyListQuery>>({
    active: '',
    customerType: '',
    pageIndex: 0,
    pageSize: 25,
  });
  private filter$ = this.filterSubject.asObservable();

  private searchSubject = new BehaviorSubject<string>('');
  private search$ = this.searchSubject.asObservable();

  private _totalCount$ = new BehaviorSubject<number>(0);

  constructor(private agencyService: AgencyService) {
    super();

    this.totalCount$ = this._totalCount$.asObservable();

    this.filters$ = combineLatest([this.filter$, this.search$]).pipe(
      map(([filters, search]) => ({ ...filters, search }))
    );

    this.agencyService
      .getAgencies({ active: false })
      .pipe(
        map(({ agencies = [] }) => {
          const deleteSoon = agencies.filter((a) => {
            if (!a.active && a.inactiveSince) {
              const time = new Date(a.inactiveSince).getTime();
              return time <= notifPeriodTime;
            }
            return false;
          });
          return deleteSoon;
        })
      )
      .subscribe((deleteSoonAgencies) => {
        this.deletionCandidates$.next(deleteSoonAgencies);
      });
  }

  connect(): Observable<Agency[]> {
    return this.filters$.pipe(
      debounceTime(500),
      switchMap((filters) => this.agencyService.getAgencies(filters)),
      tap(({ totalCount = 0 }) => {
        this._totalCount$.next(totalCount);
      }),
      map(({ agencies }) => agencies)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  updateSearch({ search }: { search: string }) {
    this.searchSubject.next(search);
    this.resetPage();
  }

  updateCustomerType(value: CustomerType): void {
    this.updateFilters({ customerType: value });
    this.resetPage();
  }

  updateActive(value: boolean): void {
    this.updateFilters({ active: value });
    this.resetPage();
  }

  updateFilters(filters: Partial<AgencyListQuery>): void {
    this.filterSubject.next({ ...this.filterSubject.value, ...filters });
  }

  resetPage(): void {
    this.updateFilters({ pageIndex: 0 });
  }
}
