import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agency } from '@models/agency';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgencyListQuery } from '@models/agency-filter';

interface ListResponse {
  agencies: Agency[];
  totalCount: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  readonly baseUrl = `${environment.api.cws.endpoint}/agency`;

  constructor(private httpClient: HttpClient) {}

  getAgencies(
    agencyListQuery: Partial<AgencyListQuery> = {}
  ): Observable<ListResponse> {
    const params = this.getHttpParams(agencyListQuery);
    return this.httpClient
      .get<Agency[]>(this.baseUrl, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const agencies = response.body ?? [];

          return {
            agencies,
            totalCount: this.getTotalCount(response),
            pageSize: agencyListQuery.pageSize ?? agencies.length,
          };
        })
      );
  }

  getAgency(id: string): Observable<Agency> {
    return this.httpClient.get<Agency>(`${this.baseUrl}/${id}`);
  }

  createAgency(agency: Omit<Agency, '_id'>): Observable<Agency> {
    const validAgency = this.sanitizeAgency(agency);
    return this.httpClient.post<Agency>(this.baseUrl, validAgency);
  }

  updateAgency({ _id, ...agency }: Agency): Observable<Agency> {
    const validAgency = this.sanitizeAgency(agency);
    return this.httpClient.put<Agency>(`${this.baseUrl}/${_id}`, validAgency);
  }

  deleteAgency(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  sanitizeAgency(agency: Omit<Agency, '_id'>) {
    if (agency.freeTrialExpirationDate === null) {
      delete agency.freeTrialExpirationDate;
    }

    return agency;
  }

  private getHttpParams({
    active,
    customerType,
    pageIndex,
    pageSize,
    search,
  }: Partial<AgencyListQuery> = {}) {
    let params = new HttpParams().appendAll({
      orderBy: 'name',
      order: 'asc',
      active: 'all',
    });

    if (active !== undefined && active !== '') {
      params = params.set('active', String(active));
    }

    if (customerType !== undefined && customerType !== '') {
      params = params.set('customerType', String(customerType));
    }

    if (pageIndex !== undefined) {
      params = params.append('page', String(pageIndex));
    }

    if (pageSize !== undefined) {
      params = params.append('limit', String(pageSize));
    }

    if (search !== undefined && search !== '') {
      params = params.set('search', search);
    }

    return params;
  }

  private getTotalCount({ headers }: { headers: HttpHeaders }) {
    return headers.get('X-CWS-Total') ? Number(headers.get('X-CWS-Total')) : 0;
  }
}
