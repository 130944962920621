import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client } from '@models/client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  readonly baseUrl = `${environment.api.cws.endpoint}/client`;

  constructor(private httpClient: HttpClient) {}

  getClients(agencyId: string): Observable<Client[]> {
    return this.httpClient
      .get<Client[]>(this.baseUrl, {
        params: {
          order: 'asc',
          orderBy: 'name',
          agency: agencyId,
        },
      })
      .pipe(map((response) => response ?? []));
  }

  getClient(id: string): Observable<Client> {
    return this.httpClient.get<Client>(`${this.baseUrl}/${id}`);
  }

  deleteClient(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  updateClient(
    clientId: string,
    updateData: Partial<kit.IClient>
  ): Observable<Client> {
    return this.httpClient.put<Client>(
      `${this.baseUrl}/${clientId}`,
      updateData
    );
  }

  createClient(client: Client, agencyId: string): Observable<Client> {
    return this.httpClient.post<Client>(this.baseUrl, {
      name: client.name,
      agency: agencyId,
      hubspotCompanyId: client.hubspotCompanyId,
    });
  }
}
