import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FeatureService } from '@storykit/ui-components';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from '@core/auth/auth.service';

import { environment } from '@environment';
import { Version } from '../../../app/version';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private featureService: FeatureService,
    private authService: AuthService
  ) {}

  public canActivate(): Observable<boolean> {
    return this.authService.currentUser$.pipe(
      mergeMap((user) =>
        this.featureService.init(
          environment,
          user as kit.IUser,
          'admin',
          Version.number
        )
      ),
      map((status) => {
        if (!status.success) {
          console.log("PostHog config wasn't loaded properly");
        }

        // Shouldn't stop the application from working.
        return true;
      })
    );
  }
}
