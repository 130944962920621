import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { CwsService } from '@core/services/cws.service';
import { ClientsAgencies } from '@models/cws';
import { accessTypeLabels, accessTypes, roleLabels, roles } from '@models/user';
import { UserAccessForm } from '@shared/user-access-form/user-access-form';
import { ElementId } from '@storykit/constants';

@Component({
  selector: 'app-user-access-form',
  templateUrl: './user-access-form.component.html',
  styleUrls: ['./user-access-form.component.scss'],
  styles: [],
})
export class UserAccessFormComponent implements OnInit, OnDestroy {
  @Input() clientsAgencies$!: Observable<ClientsAgencies>;

  @Input() values$?: Observable<UntypedFormArray>;

  @Input() form!: UntypedFormGroup;

  @Input() count!: number;

  accessRoles = roles;

  roleLabels = roleLabels;

  accessTypes = accessTypes;

  accessTypeLabels = accessTypeLabels;

  accessControls$!: Observable<AbstractControl[]>;

  agencies: kit.IAgency[] = [];

  elementId = ElementId;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  private filterAgencyCache = new Map<string, kit.IAgency[]>();

  constructor(
    private userAccessForm: UserAccessForm,
    private cwsService: CwsService
  ) {}

  get getAccessControls() {
    return (this.form.get('access') as UntypedFormArray).controls;
  }

  get getAccessFormArray() {
    return this.form.get('access') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.values$?.subscribe((values) => this.form.setControl('access', values));
    this.accessControls$ = this.form.valueChanges.pipe(
      startWith(undefined),
      map(() => this.getAccessControls)
    );
    this.clientsAgencies$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(({ agencies }) => {
        this.agencies = agencies;
      });
  }

  createAccessRow() {
    this.getAccessFormArray.push(this.userAccessForm.createFormGroup());
  }

  deleteAccessRow(index: number) {
    this.getAccessFormArray.removeAt(index);
  }

  onAccessTypeChange(index: number) {
    this.getAccessControls[index].patchValue({ id: '' });
  }

  getClientAgencies$(arrFormIndex: number) {
    const agencyId = this.getAccessControls[arrFormIndex]?.value.agencyId;
    return this.cwsService.getClientsByAgencyId(agencyId);
  }

  getAgency(arrFormIndex: number, formControlName = 'agencyId') {
    const agencyId =
      this.getAccessControls[arrFormIndex]?.value[formControlName];
    return this.agencies.find((a) => a._id === agencyId);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // we trigger it on each tick, so it re-filter the same stuff 12 times per any action
  // like mouse moves, clicks, etc.
  // it's too much of actions, so we need to optimize it
  // this approach creates a map for each search query-index pair
  // but as we don't copy items, it's just several arrays of references, so it's not take a lot of memory
  getFilteredAgencies(index: number): kit.IAgency[] {
    if (!this.agencies) {
      return [];
    }
    const search = (
      this.getAccessControls[index]?.value.agencyFilter || ''
    ).toLowerCase() as string;
    if (!search) {
      return this.agencies;
    }
    const key = `${index}-${search}`;
    if (this.filterAgencyCache.has(key)) {
      return this.filterAgencyCache.get(key) as kit.IAgency[];
    } else {
      const cache = this.agencies.filter((agency) =>
        agency.name.toLowerCase().includes(search)
      );
      this.filterAgencyCache.set(key, cache);
      return cache;
    }
  }
}
