import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { SlideDefinition } from '@models/slide-definition';
import { SlideDefinitionListQuery } from '@models/slide-definition-filter';
import { SlideDefinitionService } from '@core/services/slide-definition.service';

@Injectable()
export class SlideDefinitionListDataSource extends DataSource<SlideDefinition> {
  filters$: Observable<Partial<SlideDefinitionListQuery>>;
  totalCount$: Observable<number>;

  private filterSubject = new BehaviorSubject<
    Partial<SlideDefinitionListQuery>
  >({
    pageIndex: 0,
    pageSize: 25,
    accessCategory: '',
    garboSceneLocation: true,
  });
  private filter$ = this.filterSubject.asObservable();

  private searchSubject = new BehaviorSubject<string>('');
  private search$ = this.searchSubject.asObservable();

  private _totalCount$ = new BehaviorSubject<number>(0);

  constructor(private slideDefinitionService: SlideDefinitionService) {
    super();

    this.totalCount$ = this._totalCount$.asObservable();

    this.filters$ = combineLatest([this.filter$, this.search$]).pipe(
      map(([filters, search]) => ({ ...filters, search }))
    );
  }

  connect(): Observable<SlideDefinition[]> {
    return this.filters$.pipe(
      debounceTime(500),
      switchMap((filters) =>
        this.slideDefinitionService.getSlideDefinitions(filters)
      ),
      tap(({ totalCount = 0 }) => {
        this._totalCount$.next(totalCount);
      }),
      map(({ slideDefinitions }) => slideDefinitions)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  updateSearch({ search }: { search: string }) {
    this.searchSubject.next(search);
    this.resetPage();
  }

  updateAccessCategory(value: kit.vs.TVideoDefinitionAccessCategory): void {
    this.updateFilters({ accessCategory: value });
    this.resetPage();
  }

  updateGarboSceneLocation(value: boolean): void {
    this.updateFilters({ garboSceneLocation: value });
    this.resetPage();
  }

  updateFilters(filters: Partial<SlideDefinitionListQuery>): void {
    this.filterSubject.next({ ...this.filterSubject.value, ...filters });
  }

  resetPage(): void {
    this.updateFilters({ pageIndex: 0 });
  }
}
