import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpLoadingService {
  private urls: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  loading$: Observable<boolean> = this.urls.pipe(
    debounceTime(700),
    distinctUntilChanged(),
    map((urls) => urls.length > 0)
  );

  addLoadingRequest(url: string) {
    if (!this.urlExists(url)) {
      this.urls.next([...this.urls.getValue(), url]);
    }
  }

  removeLoadingRequest(url: string) {
    if (this.urlExists(url)) {
      const urls = this.urls.getValue().filter((u) => u !== url);
      this.urls.next(urls);
    }
  }

  private urlExists(url: string) {
    return this.urls.getValue().includes(url);
  }
}
