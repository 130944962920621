export enum UserNotification {
  Created = 'User was created',
  NotCreated = 'User was not created',
  Updated = 'User was updated',
  NotUpdated = 'User was not updated',
  Deleted = 'User was deleted',
  NotDeleted = 'User was not deleted',
  CwsFailed = 'No agencies or clients found',
  InviteEmailSent = 'Invite email have been sent',
  InviteEmailSentError = 'Invite email have not been sent',
}
