import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-save-confirm-dialog',
  templateUrl: './bulk-save-confirm-dialog.component.html',
  styles: [],
})
export class BulkSaveConfirmDialogComponent {
  static open(dialog: MatDialog) {
    return dialog.open(BulkSaveConfirmDialogComponent, {
      maxWidth: 400,
    });
  }
}
