import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

type MessageLevels = 'error' | 'success' | 'warning';
type MessageColors = 'negative' | 'positive' | 'warning';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private duration = 8000;
  private horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private snackBar: MatSnackBar) {}

  show(level: MessageLevels, message: string) {
    return this[level](message);
  }

  private error(message: string) {
    return this.openSnackbar(message, this.getSnackBarBgColor('negative'));
  }

  private success(message: string) {
    return this.openSnackbar(message, this.getSnackBarBgColor('positive'));
  }

  private warning(message: string) {
    return this.openSnackbar(message, this.getSnackBarBgColor('warning'));
  }

  private getSnackBarBgColor(color: MessageColors) {
    const config = new MatSnackBarConfig();
    config.panelClass = [`u-bgcolor--${color}`];
    return config;
  }

  private openSnackbar(
    message: string,
    config: MatSnackBarConfig = {}
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, undefined, {
      ...config,
      panelClass: [`${config.panelClass}`, 'notification'],
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
