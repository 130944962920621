import { NgModule, ErrorHandler } from '@angular/core';
import {
  ApmModule,
  ApmService,
  ApmErrorHandler,
} from '@elastic/apm-rum-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { environment } from '@environment';
import { envVars } from '../environments/env';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '@views/partials/header/header.component';
import { AuthClientModule } from '@storykit/auth-client';
import { LoginComponent } from '@core/auth/login/login.component';
import { UserComponent } from '@views/user/user/user.component';
import { UserFormComponent } from '@views/user/user/user-form.component';
import { AuthService } from '@core/auth/auth.service';
import { RedirectComponent } from './core/auth/redirect/redirect.component';
import { AuthorizationInterceptor } from '@core/auth/authorization.interceptor';
import { AppLoggedInComponent } from './appLoggedIn.component';
import { NotAllowedComponent } from './core/auth/not-allowed/not-allowed.component';
import { MaterialModule } from '@shared/material-design/material.module';
import { UserListViewComponent } from './views/user/user-list/user-list.component';
import { UserListComponent } from '@shared/user-list/user-list.component';
import { ConfirmDialogComponent } from './views/partials/confirm-dialog/confirm-dialog.component';
import { HttpErrorInterceptor } from '@shared/http/http-error.interceptor';
import { GlobalProgressComponent } from './views/partials/global-progress/global-progress.component';
import { HttpRequestInterceptor } from '@shared/http/http-request.interceptor';
import { UserBulkAddComponent } from './views/user/user-bulk/user-bulk-add.component';
import { UserBulkTableComponent } from './views/user/user-bulk/user-bulk-table.component';
import { BulkSaveConfirmDialogComponent } from '@views/user/user-bulk/bulk-save-confirm-dialog.component';
import { UserAccessFormComponent } from './views/user/user-access-form/user-access-form.component';
import { ErrorComponent } from '@shared/error/error.component';
import { AgencyListComponent } from '@views/agency/agency-list/agency-list.component';
import { AgencyComponent } from '@views/agency/agency/agency.component';
import { InputToClipboardComponent } from './views/partials/input-to-clipboard/input-to-clipboard.component';
import { AdvancedSectionComponent } from './views/user/user/advanced-section.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FreeTrialFormComponent } from '@shared/free-trial-form/free-trial-form.component';
import { MatSliderModule } from '@angular/material/slider';
import { FreemiumFormComponent } from '@shared/freemium-form/freemium-form.component';
import { PremiumFeaturesFormComponent } from '@shared/premium-features-form/premium-features-form.component';
import { FontCalcListComponent } from './shared/font-calc-list/font-calc-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {
  ElementIdDirective,
  FileDropModule,
  FontUploadService,
  IconComponent,
  SearchFieldModule,
} from '@storykit/ui-components';
import { AppVersionModule } from '@core/version/version.module';
import { SlideDefinitionListComponent } from '@views/slide-definition/slide-definition-list/slide-definition-list.component';
import { SlideDefinitionComponent } from '@views/slide-definition/slide-definition/slide-definition.component';
import { SlideDefinitionAccessFormComponent } from '@views/slide-definition/slide-definition/slide-definition-access-form/slide-definition-access-form.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-var
  var __sk_settings: typeof envVars;
}

// Mutate contents in env with values from __sk_settings
if (globalThis.__sk_settings) {
  Object.keys(environment).forEach((key) => {
    delete environment[key as keyof typeof envVars];
  });
  Object.assign(environment, globalThis.__sk_settings);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ErrorComponent,
    LoginComponent,
    UserComponent,
    UserFormComponent,
    RedirectComponent,
    AppLoggedInComponent,
    NotAllowedComponent,
    UserListViewComponent,
    ConfirmDialogComponent,
    GlobalProgressComponent,
    UserBulkAddComponent,
    UserBulkTableComponent,
    BulkSaveConfirmDialogComponent,
    UserAccessFormComponent,
    AgencyListComponent,
    AgencyComponent,
    InputToClipboardComponent,
    AdvancedSectionComponent,
    FreeTrialFormComponent,
    FreemiumFormComponent,
    PremiumFeaturesFormComponent,
    FontCalcListComponent,
    UserListComponent,
    SlideDefinitionListComponent,
    SlideDefinitionComponent,
    SlideDefinitionAccessFormComponent,
  ],
  imports: [
    ApmModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthClientModule.forRoot(environment.auth0, environment.authProvider),
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSliderModule,
    ScrollingModule,
    FileDropModule.forRoot(environment),
    SearchFieldModule,
    AppVersionModule,
    ElementIdDirective,
    IconComponent,
  ],
  providers: [
    ApmService,
    AuthService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    FontUploadService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        appearance: 'outline',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(service: ApmService) {
    service.init({
      serviceName: 'admin',
      serverUrl: environment.apm.serverUrl,
      environment: environment.apm.environment,
      active: !!environment.apm.serverUrl,
      disableInstrumentations: ['eventtarget', 'history'],
      distributedTracingOrigins: [environment.api.cws.endpoint],
    });
  }
}
