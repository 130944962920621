import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AgencyService } from '@core/services/agency.service';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { AgencyListQuery } from '@models/agency-filter';
import { AgencyListDataSource } from './agency-list.dataSource';
import { CustomerType as CustomerTypes, ElementId } from '@storykit/constants';

@Component({
  selector: 'app-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: [
    '../../partials/table/table.scss',
    '../../user/user-list/user-list-table.scss',
  ],
})
export class AgencyListComponent {
  customerTypes = CustomerTypes;
  dataSource = new AgencyListDataSource(this.agencyService);

  displayedColumns = ['name', 'customerType', 'active', 'actions'];

  public elementId = ElementId;
  filters: Partial<AgencyListQuery> = {};

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private agencyService: AgencyService
  ) {
    this.breadcrumbsService.set([{ label: 'Agencies', link: '/agencies' }]);

    this.dataSource.filters$.subscribe((filters) => {
      this.filters = filters;
    });
  }

  clearSearch() {
    this.dataSource.updateSearch({ search: '' });
  }

  handleSearchChange(value: string): void {
    this.dataSource.updateSearch({ search: value });
  }

  handleCustomerTypeChange(event: MatSelectChange): void {
    this.dataSource.updateCustomerType(event.value);
  }

  handleActiveChange(event: MatSelectChange): void {
    this.dataSource.updateActive(event.value);
  }

  handlePageChange(event: Partial<AgencyListQuery>): void {
    this.dataSource.updateFilters({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  deletionDate(inactiveSince: string) {
    const date = new Date(inactiveSince);
    date.setMonth(date.getMonth() + 6);
    return date;
  }
}
