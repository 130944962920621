<div class="container container--xs u-marginTxxl">
  <form [formGroup]="form" *ngIf="(loading$ | async) === false">
    <h2>User {{ blocked ? '- Blocked' : '' }}</h2>
    <app-user-form
      [form]="form"
      [verified]="verified"
      (resendInviteEmail)="resendInviteEmail()"
    ></app-user-form>

    <mat-tab-group animationDuration="0ms" class="section-divider">
      <mat-tab>
        <ng-template mat-tab-label>
          <div [skElementId]="elementId.Admin.UserMenuItemAccess">
            Access ({{ accessCount }})
          </div>
        </ng-template>

        <div class="u-marginTm">
          <app-user-access-form
            [form]="accessForm"
            [clientsAgencies$]="clientsAgencies$"
            [values$]="accessValues$"
            [count]="accessCount"
          >
          </app-user-access-form>
        </div>
      </mat-tab>

      <ng-container [ngSwitch]="mode$ | async">
        <ng-container *ngSwitchCase="modes.Edit">
          <mat-tab>
            <ng-template mat-tab-label>
              <div [skElementId]="elementId.Admin.UserMenuItemAdvanced">
                Advanced
              </div>
            </ng-template>
            <div class="u-marginTl u-paddingBxl">
              <app-advanced-section
                [form]="form"
                [formLoading]="formLoading"
                [userId$]="userId$"
                [resetLink$]="resetLink$"
                [ipBlock$]="ipBlock$"
                [blocked]="blocked"
                [flaggedForDeletion]="flaggedForDeletion"
                (resetPassword)="getResetLink()"
                (block)="openBlockDialog()"
                (delete)="openDeleteDialog()"
                (checkIpBlock)="checkIpBlock()"
                (removeIpBlock)="removeIpBlock()"
                (removeDeletedStatus)="removeDeletedStatus()"
              >
              </app-advanced-section>
            </div>
          </mat-tab>
        </ng-container>
      </ng-container>
    </mat-tab-group>

    <ng-container [ngSwitch]="mode$ | async">
      <ng-container *ngSwitchCase="modes.Add">
        <button
          class="u-marginTl u-marginBxl"
          [disabled]="formLoading || form.invalid"
          mat-flat-button
          data-test-id="create-user-button"
          color="primary"
          (click)="createUser()"
          [skElementId]="elementId.Admin.UserCreateButton"
        >
          Create User
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="modes.Edit">
        <button
          class="u-marginTxl"
          [disabled]="formLoading || form.invalid"
          mat-flat-button
          data-test-id="update-user-button"
          color="primary"
          (click)="updateUser()"
          [skElementId]="elementId.Admin.UserUpdateButton"
        >
          Update User
        </button>
      </ng-container>
    </ng-container>
  </form>
  <app-error [observable$]="user$"></app-error>
</div>
