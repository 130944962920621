import { AbstractControl, ValidationErrors } from '@angular/forms';

export class FormValidator {
  static notIncludesValidator(
    validationErrorField: Record<string, any>,
    list: readonly string[]
  ) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && !list.includes(control.value)) {
        return validationErrorField;
      }

      return null;
    };
  }
}
