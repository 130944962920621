import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomerType as CustomerTypes, ElementId } from '@storykit/constants';

@Component({
  selector: 'app-premium-features-form',
  templateUrl: './premium-features-form.component.html',
  styleUrls: ['../../../styles/settings-form.scss'],
})
export class PremiumFeaturesFormComponent {
  @Input() form!: FormGroup;

  elementId = ElementId;

  public customerTypes = CustomerTypes;
}
