<div [formGroup]="accessForm" class="access-section">
  <div>
    <div class="u-marginBm" *ngIf="accessForm.value.access?.length === 0">
      No access added
    </div>

    <div
      formArrayName="access"
      *ngFor="let access of accessEntryControls; let i = index"
    >
      <div class="u-flex" [formGroupName]="i">
        <div class="u-marginBm">
          <mat-label class="o-form-label">Access type</mat-label>
          <mat-form-field subscriptSizing="dynamic" class="margin-right-small">
            <mat-select
              (selectionChange)="onAccessTypeChange(i)"
              formControlName="type"
            >
              <mat-option
                *ngFor="let accessType of accessTypes"
                value="{{ accessType }}"
                >{{ accessTypeLabels[accessType] }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="access.value.type === 'client'" class="margin-left-large">
          <mat-label class="o-form-label">Client</mat-label>
          <mat-form-field subscriptSizing="dynamic" class="margin-right-small">
            <mat-select formControlName="id" placeholder="Client">
              <mat-option>
                <ngx-mat-select-search
                  formControlName="search"
                  [hideClearSearchButton]="true"
                  placeholderLabel="Client"
                  noEntriesFoundLabel="No matching client found"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option
                *ngFor="let client of getFilteredClients(i)"
                value="{{ client._id }}"
              >
                {{ client.name }} ({{ client._id }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="access.value.type === 'agency'" class="margin-left-large">
          <mat-label class="o-form-label">Agency</mat-label>
          <mat-form-field subscriptSizing="dynamic" class="margin-right-small">
            <mat-select formControlName="id" placeholder="Agency">
              <mat-option>
                <ngx-mat-select-search
                  formControlName="search"
                  [hideClearSearchButton]="true"
                  placeholderLabel="Agency"
                  noEntriesFoundLabel="No matching agency found"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option
                *ngFor="let agency of getFilteredAgencies(i)"
                value="{{ agency._id }}"
              >
                {{ agency.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="u-marginLs u-marginBm u-flex u-flex-align--center">
          <button mat-icon-button (click)="deleteAccessRow(i)">
            <app-icon
              name="trash"
              class="u-color--negative u-iconsize--m o-btn__icon"
            ></app-icon>
          </button>
        </div>
      </div>
    </div>

    <button mat-flat-button (click)="createAccessRow()" color="primary">
      Add Access
    </button>
  </div>
</div>
