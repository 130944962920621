<div [skElementId]="elementId.Admin.ConfirmationModal">
  <h1 mat-dialog-title>
    {{ capitalize(data.action) }} {{ capitalize(data.subject) }}
  </h1>
  <div [skElementId]="elementId.Admin.ConfirmationModalText">
    <div
      mat-dialog-content
      *ngIf="
        lowercase(data.subject) !== 'agency' &&
        lowercase(data.subject) !== 'clients'
      "
    >
      Are you sure you want to {{ lowercase(data.action) }} this
      {{ data.subject }}<span *ngIf="data.name">: "{{ data.name }}"</span>?
    </div>
    <div
      mat-dialog-content
      *ngIf="
        lowercase(data.subject) === 'agency' &&
        lowercase(data.action) === 'delete'
      "
    >
      This {{ data.subject }} will be marked as inactive and be queued for
      deletion the next day.<br />
      Are you sure you want to continue?
    </div>
    <div
      mat-dialog-content
      *ngIf="
        lowercase(data.subject) === 'clients' &&
        lowercase(data.action) === 'delete'
      "
    >
      There are {{ data.amount }} {{ data.subject }} that will be deleted with
      this update. Are you sure you want to continue?
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="warn"
      data-test-id="block-dialog-button"
      [mat-dialog-close]="true"
      [skElementId]="elementId.Admin.ConfirmationModalButtonDelete"
    >
      {{ capitalize(data.action) }}
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      [skElementId]="elementId.Admin.ConfirmationModalButtonCancel"
    >
      Cancel
    </button>
  </div>
</div>
