<div class="container u-marginTxxl u-flex u-flex-align--center">
  <div class="u-marginBm u-marginRs">
    <mat-label class="o-form-label">Search</mat-label>
    <mat-form-field
      class="u-marginRxs"
      [skElementId]="elementId.Admin.AgencySearchField"
    >
      <input
        [(ngModel)]="filters.search"
        (ngModelChange)="handleSearchChange($event)"
        type="text"
        matInput
        placeholder="Search"
      />
      <button
        *ngIf="filters.search"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
        class="u-iconsize--s"
      >
        <app-icon name="x"></app-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="u-marginBm u-marginRs">
    <mat-label class="o-form-label">Customer type</mat-label>
    <mat-form-field class="u-marginRxs">
      <mat-select
        [value]="filters.customerType"
        (selectionChange)="handleCustomerTypeChange($event)"
      >
        <mat-option value="">All</mat-option>
        <mat-option
          *ngFor="let type of customerTypes | keyvalue"
          value="{{ type.value }}"
        >
          {{ type.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="u-marginBm">
    <mat-label class="o-form-label">Active</mat-label>
    <mat-form-field>
      <mat-select
        [value]="filters.active"
        (selectionChange)="handleActiveChange($event)"
      >
        <mat-option value="">All</mat-option>
        <mat-option [value]="true">True</mat-option>
        <mat-option [value]="false">False</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="u-marginBm u-marginTm align-right">
    <button
      mat-raised-button
      color="primary"
      [matMenuTriggerFor]="menu"
      [skElementId]="elementId.Admin.AgencyBtnAgencyActions"
    >
      Agency actions
    </button>
    <mat-menu #menu="matMenu">
      <div [skElementId]="elementId.Admin.AgencyDropDownMenu">
        <a
          [routerLink]="['/agencies/add']"
          mat-button
          [skElementId]="elementId.Admin.AgencyMenuItemCreateAgency"
        >
          <app-icon
            name="users-three"
            weight="fill"
            class="u-marginRs u-iconsize--m o-btn__icon"
          ></app-icon>
          <span>Create agency</span>
        </a>
      </div>
    </mat-menu>
  </div>
</div>

<div class="container u-paddingBxxl u-flex">
  <div class="u-width--full">
    <h3>Agencies</h3>
    <mat-table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z0"
      [skElementId]="elementId.Admin.AgencyTable"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell
          *matCellDef="let agency"
          [skElementId]="elementId.Admin.AgencyTableRowName"
          >{{ agency.name }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="customerType">
        <mat-header-cell *matHeaderCellDef>Customer Type</mat-header-cell>
        <mat-cell
          *matCellDef="let agency"
          [skElementId]="elementId.Admin.AgencyTableRowCustomerType"
          >{{ agency.customerType }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
        <mat-cell
          *matCellDef="let agency"
          [skElementId]="elementId.Admin.AgencyTableRowStatus"
          >{{ agency.active }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell
          *matCellDef="let agency"
          [skElementId]="elementId.Admin.AgencyTableRowAction"
        >
          <a
            [routerLink]="['/agencies', agency._id]"
            mat-flat-button
            color="primary"
          >
            Edit
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let dataIndex = index"
        [skElementId]="elementId.Admin.AgencyTableRow"
        [skElementIdIndex]="dataIndex"
      >
      </mat-row>
    </mat-table>
    <mat-paginator
      [length]="dataSource.totalCount$ | async"
      [pageSize]="(dataSource.filters$ | async)?.pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [pageIndex]="(dataSource.filters$ | async)?.pageIndex"
      (page)="handlePageChange($event)"
    >
    </mat-paginator>
  </div>
  <div
    class="u-width--full u-marginL"
    *ngIf="(dataSource.deletionCandidates$ | async)?.length"
  >
    <h3>Agencies to be deleted soon</h3>
    <mat-table
      mat-table
      [dataSource]="dataSource.deletionCandidates$"
      class="mat-elevation-z0"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let agency">{{ agency.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="customerType">
        <mat-header-cell *matHeaderCellDef>Customer Type</mat-header-cell>
        <mat-cell *matCellDef="let agency">{{ agency.customerType }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef>Deletion Date</mat-header-cell>
        <mat-cell *matCellDef="let agency">{{
          deletionDate(agency.inactiveSince) | date
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let agency">
          <a
            [routerLink]="['/agencies', agency._id]"
            mat-flat-button
            color="primary"
          >
            Edit
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
