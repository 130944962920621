import { Component } from '@angular/core';
import { HttpLoadingService } from '@core/services/http-loading.service';

@Component({
  selector: 'app-global-progress',
  templateUrl: './global-progress.component.html',
  styleUrls: ['./global-progress.component.scss'],
})
export class GlobalProgressComponent {
  loading$ = this.httpLoading.loading$;

  constructor(private httpLoading: HttpLoadingService) {}
}
