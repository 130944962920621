import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

interface Breadcrumb {
  label: string;
  link?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  public breadcrumbs$: Observable<Breadcrumb[]>;
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);

  constructor() {
    this.breadcrumbs$ = this.breadcrumbsSubject.asObservable();
  }

  public set(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbsSubject.next(breadcrumbs);
  }
}
