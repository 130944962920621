import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CwsService } from '@core/services/cws.service';
import { accessTypeLabels, accessTypes } from '@models/slide-definition';

interface AccessEntry {
  type: 'client' | 'agency';
  id: string;
}

@Component({
  selector: 'app-slide-definition-access-form',
  templateUrl: './slide-definition-access-form.component.html',
  styles: [],
})
export class SlideDefinitionAccessFormComponent implements OnInit, OnDestroy {
  @Input() form!: UntypedFormGroup;

  accessForm = this.formBuilder.group({
    access: this.setupAccessEntryControls(),
  });

  accessTypes = accessTypes;

  accessTypeLabels = accessTypeLabels;

  agencies: kit.IAgency[] = [];
  clients: kit.IClient[] = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private cwsService: CwsService
  ) {}

  get getAccessEntries(): AccessEntry[] {
    const formValue = this.form?.value ?? {
      availableForAgencies: [],
      availableForClients: [],
    };
    const agencyControls = formValue.availableForAgencies.map(
      (agencyId: string): AccessEntry => ({
        type: 'agency',
        id: agencyId,
      })
    );
    const clientControls = formValue.availableForClients.map(
      (clientId: string): AccessEntry => ({
        type: 'client',
        id: clientId,
      })
    );
    return [...agencyControls, ...clientControls];
  }

  get accessEntryControls(): AbstractControl[] {
    return (this.accessForm.get('access') as FormArray).controls;
  }

  setupAccessEntryControls(): UntypedFormArray {
    return this.formBuilder.array(
      this.getAccessEntries.map((entry) =>
        this.formBuilder.group({
          type: [entry.type, Validators.required],
          id: [entry.id, Validators.required],
          search: [''],
        })
      )
    );
  }

  ngOnInit(): void {
    this.cwsService
      .getAgencies()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.agencies = data;
      });
    this.cwsService
      .getClients()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.clients = data;
      });

    this.setupAccessEntryControls().controls.forEach((control) => {
      (this.accessForm.get('access') as FormArray).push(control);
    });
    this.accessForm.valueChanges.subscribe(({ access }) => {
      const availableForAgencies: string[] = access
        .filter((entry: AccessEntry) => entry.type === 'agency')
        .map((entry: AccessEntry) => entry.id);
      const availableForClients: string[] = access
        .filter((entry: AccessEntry) => entry.type === 'client')
        .map((entry: AccessEntry) => entry.id);

      const availableForAgenciesControl = this.form.get(
        'availableForAgencies'
      ) as FormArray;
      availableForAgenciesControl.clear();
      availableForAgencies.forEach((agencyId) => {
        availableForAgenciesControl.push(this.formBuilder.control(agencyId));
      });

      const availableForClientsControl = this.form.get(
        'availableForClients'
      ) as FormArray;
      availableForClientsControl.clear();
      availableForClients.forEach((clientId) => {
        availableForClientsControl.push(this.formBuilder.control(clientId));
      });
    });
  }

  updateAccessEntries() {
    const control = this.accessForm.get('access') as FormArray;
    control.clear();
    this.getAccessEntries?.forEach((entry) => {
      control.push(
        this.formBuilder.group({
          type: [entry.type, Validators.required],
          id: [entry.id, Validators.required],
          search: [''],
        })
      );
    });
  }

  createAccessRow() {
    (this.accessForm.get('access') as FormArray).push(
      this.formBuilder.group({
        type: 'agency',
        id: '',
        search: '',
      })
    );
  }

  deleteAccessRow(index: number) {
    (this.accessForm.get('access') as FormArray).removeAt(index);
  }

  onAccessTypeChange(index: number) {
    (this.accessForm.get('access') as FormArray)
      .at(index)
      .patchValue({ id: '' });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getFilteredAgencies(index: number): kit.IAgency[] {
    if (!this.agencies) {
      return [];
    }
    const control = this.accessForm.get('access') as FormArray | undefined;
    if (!control) {
      return [];
    }
    // get the search keyword
    let search = control.at(index)?.value.search;
    if (!search) {
      return [...this.agencies];
    } else {
      search = search.toLowerCase();
    }
    // filter the agencies
    return this.agencies.filter(
      (agency) => agency.name.toLowerCase().indexOf(search) > -1
    );
  }

  getFilteredClients(index: number): kit.IClient[] {
    if (!this.clients) {
      return [];
    }

    const control = this.accessForm.get('access') as FormArray | undefined;
    if (!control) {
      return [];
    }
    // get the search keyword
    let search = control.at(index)?.value.search;
    if (!search) {
      return [...this.clients];
    } else {
      search = search.toLowerCase();
    }
    // filter the agencies
    return this.clients.filter(
      (client) => client.name.toLowerCase().indexOf(search) > -1
    );
  }
}
