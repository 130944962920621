export enum SlideDefinitionNotification {
  Created = 'Slide definition was created',
  NotCreated = 'Slide definition was not created',
  Updated = 'Slide definition was updated',
  NotUpdated = 'Slide definition was not updated',
  Deleted = 'Slide definition was deleted',
  NotDeleted = 'Slide definition was not deleted',
  ThemedSlidePreviewRendered = 'Themed slide preview was scheduled to render',
  FailedThemedSlidePreviewRendered = 'Failed to schedule rendering of themed slide previews',
}
