<div [formGroup]="form" class="settings-form">
  <div class="u-flex u-flex-align--start" formGroupName="featureSettings">
    <div class="u-flex u-flex-align--start">
      <div class="u-marginRm" formGroupName="videoblocks">
        <mat-label class="o-form-label">Videoblocks</mat-label>
        <mat-checkbox
          formControlName="value"
          [skElementId]="elementId.Admin.AgencyCheckboxVideoblocks"
          >Enable</mat-checkbox
        >
        <div class="u-color--warning warning-premium-features">
          Videoblocks feature is added on all
          {{ customerTypes.PRO }} & {{ customerTypes.ENTERPRISE }} service
          plans.
        </div>
      </div>
      <div class="u-marginRm" formGroupName="getty">
        <mat-label class="o-form-label">Getty Images</mat-label>
        <mat-checkbox
          formControlName="value"
          [skElementId]="elementId.Admin.AgencyCheckboxGetty"
          >Enable</mat-checkbox
        >
      </div>
      <div class="u-marginRm" formGroupName="premiumMusic">
        <mat-label class="o-form-label">Premium music</mat-label>
        <mat-checkbox
          formControlName="value"
          [skElementId]="elementId.Admin.AgencyCheckboxPremiumMusic"
          >Enable</mat-checkbox
        >
        <div class="u-color--warning warning-premium-features">
          Access to storyblocks premium music in music browser.
        </div>
      </div>
      <div class="u-marginRm" formGroupName="scriptCreators">
        <mat-label class="o-form-label">Script creator</mat-label>
        <mat-checkbox
          formControlName="value"
          [skElementId]="elementId.Admin.AgencyCheckboxScriptCreator"
          >Enable</mat-checkbox
        >
        <div class="u-color--warning warning-premium-features">
          Access to Script Creator (Airon) in script editor.
        </div>
      </div>
    </div>
  </div>
</div>
