export enum AgencyNotification {
  Created = 'Agency was created',
  NotCreated = 'Agency was not created',
  Updated = 'Agency was updated',
  NotUpdated = 'Agency was not updated',
  Deleted = 'Agency was deleted',
  NotDeleted = 'Agency was not deleted',
  FontDeleted = 'Font deleted',
  FontNotDeleted = 'Font could not be deleted',
  FontNotUploaded = 'Fonts could not be uploaded',
  FontUpdated = 'Fonts was updated',
  FontNotUpdated = 'Fonts could not be updated',
  ClientsNotDeleted = 'Clients was not deleted',
}
