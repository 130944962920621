import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { SlideDefinitionListDataSource } from './slide-definition-list.dataSource';
import { SlideDefinitionService } from '@core/services/slide-definition.service';
import { SlideDefinitionListQuery } from '@models/slide-definition-filter';
import { AccessCategory } from '@storykit/constants';

@Component({
  selector: 'app-slide-definition-list',
  templateUrl: './slide-definition-list.component.html',
  styleUrls: [
    '../../partials/table/table.scss',
    '../../user/user-list/user-list-table.scss',
  ],
})
export class SlideDefinitionListComponent {
  accessCategories = AccessCategory;
  dataSource = new SlideDefinitionListDataSource(this.slideDefinitionService);

  displayedColumns = ['displayName', 'accessCategory', 'actions'];

  filters: Partial<SlideDefinitionListQuery> = {};

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private slideDefinitionService: SlideDefinitionService
  ) {
    this.breadcrumbsService.set([
      { label: 'Slide Definitions', link: '/slide-definitions' },
    ]);

    this.dataSource.filters$.subscribe((filters) => {
      this.filters = filters;
    });
  }

  clearSearch() {
    this.dataSource.updateSearch({ search: '' });
  }

  handleSearchChange(value: string): void {
    this.dataSource.updateSearch({ search: value });
  }

  handleAccessCategoryChange(event: MatSelectChange): void {
    this.dataSource.updateAccessCategory(event.value);
  }

  handleGarboSceneLocationChange(event: boolean): void {
    this.dataSource.updateGarboSceneLocation(event);
  }

  handlePageChange(event: Partial<SlideDefinitionListQuery>): void {
    this.dataSource.updateFilters({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  deletionDate(inactiveSince: string) {
    const date = new Date(inactiveSince);
    date.setMonth(date.getMonth() + 6);
    return date;
  }
}
