import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isAuthorized().pipe(
      switchMap((isAuthorized) => {
        if (!isAuthorized) {
          return of(this.router.parseUrl('login'));
        }

        return this.authService.currentUser$.pipe(
          map((user) => {
            if (user?.role === 'administrator') {
              return true;
            }

            return this.router.parseUrl('not-allowed');
          }),
          catchError(() => of(this.router.parseUrl('not-allowed')))
        );
      })
    );
  }
}
