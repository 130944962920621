<div [formGroup]="form">
  <mat-table
    mat-table
    [dataSource]="dataSource"
    formArrayName="users"
    class="mat-elevation-z0"
  >
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef> First name </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field>
          <input
            matInput
            type="text"
            autocomplete="off"
            formControlName="firstName"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef> Last name </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field>
          <input
            matInput
            type="text"
            autocomplete="off"
            formControlName="lastName"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field>
          <input
            matInput
            type="text"
            autocomplete="off"
            formControlName="email"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of userRoles" value="{{ role }}">
              {{ userRoleLabels[role] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="language">
      <mat-header-cell *matHeaderCellDef> Language </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field>
          <mat-select formControlName="preferredLanguage">
            <mat-option
              *ngFor="let language of languages"
              value="{{ language }}"
              >{{ languageLabels[language] }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef> Remove </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <button
          *ngIf="dataSource.value.length > 1"
          mat-icon-button
          [disabled]="row.value.disabled"
          (click)="deleteUserRow(index)"
        >
          <app-icon
            name="trash"
            class="u-flex u-flex-justify--center u-iconsize--m o-btn__icon"
            [ngClass]="{
              'u-color--light': row.value.disabled,
              'u-color--negative': !row.value.disabled
            }"
          ></app-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Saved </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <mat-spinner
          *ngIf="row.value.status === statusCodes.InProgress"
          [value]="100"
          [diameter]="20"
        ></mat-spinner>
        <app-icon
          *ngIf="row.value.status === statusCodes.Success"
          name="check"
          class="u-color--positive u-iconsize--m o-btn__icon"
        ></app-icon>

        <span
          [matTooltip]="row.value.errorMessage"
          *ngIf="row.value.status === statusCodes.Error"
        >
          <app-icon
            name="bug"
            class="u-color--negative u-iconsize--m o-btn__icon"
          ></app-icon>
        </span>
      </mat-cell>
    </ng-container>
  </mat-table>
</div>
