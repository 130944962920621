<div [formGroup]="form" class="freemium">
  <div class="u-flex u-flex-align--start" formGroupName="featureSettings">
    <div class="u-flex u-flex-align--center">
      <div class="u-marginRm">
        <mat-label class="o-form-label">Render Credits Amount </mat-label>
        <mat-form-field class="u-marginRxs" formGroupName="renderCredits">
          <input type="number" formControlName="value" matInput min="0" />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
