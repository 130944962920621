import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObservableCacheService {
  private cache$ = new Map();

  get<T>(key: string, observable: Observable<T>): Observable<T> {
    if (!this.cache$.get(key)) {
      this.setCache(key, observable);
    }

    return this.getCache(key);
  }

  removeCacheEntry(key: string): void {
    this.cache$.delete(key);
  }

  private setCache<T>(key: string, observable: Observable<T>) {
    this.cache$.set(key, observable);
  }

  private getCache<T>(key: string): Observable<T> {
    return this.cache$.get(key);
  }
}
