import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  UntypedFormGroup,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { languages, MutableUser } from '@models/user';
import { FormValidator } from '@shared/form-validators/form-validator';
import { allowedAdminRoleEmailPatterns } from '@models/user';
import { environment } from '@environment';

const initialUser: MutableUser = {
  firstName: '',
  lastName: '',
  role: 'customer',
  email: '',
  access: [],
  preferredLanguage: 'eng',
  sso: {
    enabled: false,
    provider: '',
    connectionName: '',
  },
  auth0UserId: '',
};

@Injectable({
  providedIn: 'root',
})
export class UserForm {
  constructor(private formBuilder: UntypedFormBuilder) {}

  createFormGroup(
    user: Partial<MutableUser> = initialUser,
    additionalFormControllers?: Record<string, any>
  ) {
    const formControllers = {
      firstName: [user.firstName],
      lastName: [user.lastName],
      role: [user.role, Validators.required],
      email: [user.email, [Validators.required, Validators.email]],
      preferredLanguage: [
        user.preferredLanguage,
        [
          Validators.required,
          FormValidator.notIncludesValidator(
            { preferredLanguage: true },
            languages
          ),
        ],
      ],
      sso: new FormGroup({
        enabled: new FormControl(user.sso?.enabled),
        provider: new FormControl(user.sso?.provider),
        connectionName: new FormControl(user.sso?.connectionName),
      }),
      auth0UserId: [user.auth0UserId],
    };

    return this.formBuilder.group(
      additionalFormControllers
        ? { ...formControllers, ...additionalFormControllers }
        : formControllers,
      {
        validators: [this.roleByEmailValidator],
      }
    );
  }

  private notEmailValidator(control: AbstractControl): ValidationErrors | null {
    const required = Validators.required(control);

    if (required) {
      return required;
    }

    const errors = Validators.email(control);

    if (errors) {
      return null;
    }

    return { notEmail: {} };
  }

  private roleByEmailValidator(
    form: UntypedFormGroup
  ): ValidationErrors | null {
    const email = form.get('email')?.value;
    const role = form.get('role')?.value;

    if (environment.production && email && role === 'administrator') {
      const isEmailCanBeAdmin = allowedAdminRoleEmailPatterns.some((pattern) =>
        email.includes(pattern)
      );

      return isEmailCanBeAdmin ? null : { roleByEmail: true };
    }

    return null;
  }
}
