<div class="section">
  <div [formGroup]="form">
    <h3>Auth0 ID</h3>
    <mat-form-field class="u-marginRxs">
      <input
        #in
        type="text"
        matInput
        formControlName="auth0UserId"
        placeholder="Auth0 ID"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <h3>Password reset</h3>
  <button
    *ngIf="userId$ | async"
    (click)="resetPassword.emit()"
    mat-flat-button
    color="primary"
    [skElementId]="elementId.Admin.UserButtonGetPasswordResetLink"
  >
    Get password-reset link
  </button>
  <div *ngIf="resetLink$" class="u-marginTs u-marginBm">
    <app-input-to-clipboard
      [label]="'Copy password-reset link'"
      [content]="resetLink$ | async"
      [notificationMsg]="'Reset link was copied'"
    >
    </app-input-to-clipboard>
  </div>

  <h3>IP Block</h3>
  <button
    *ngIf="(ipBlock$ | async) === false || (ipBlock$ | async) === null"
    mat-flat-button
    [disabled]="formLoading || form.invalid"
    color="primary"
    class="u-marginBm"
    (click)="checkIpBlock.emit()"
    [skElementId]="elementId.Admin.UserButtonCheckIPBlock"
  >
    {{ (ipBlock$ | async) === false ? 'User not blocked' : 'Check IP block' }}
  </button>
  <button
    *ngIf="(ipBlock$ | async) === true"
    mat-stroked-button
    [disabled]="formLoading || form.invalid"
    color="warn"
    class="u-marginBm u-marginLs"
    (click)="removeIpBlock.emit()"
    [skElementId]="elementId.Admin.UserButtonRemoveIPBlock"
  >
    Unblock user
  </button>

  <h3>Danger zone</h3>
  <button
    mat-flat-button
    [disabled]="formLoading || form.invalid"
    color="warn"
    (click)="delete.emit()"
    [skElementId]="elementId.Admin.UserButtonDelete"
  >
    Delete User
  </button>
  <button
    *ngIf="flaggedForDeletion"
    mat-stroked-button
    [disabled]="formLoading"
    color="warn"
    class="u-marginLs"
    (click)="removeDeletedStatus.emit()"
    [skElementId]="elementId.Admin.UserButtonRemoveDeletionFlag"
  >
    Remove Deletion Flag
  </button>
  <button
    mat-stroked-button
    [disabled]="formLoading"
    color="warn"
    class="u-marginLs"
    (click)="block.emit()"
    [skElementId]="elementId.Admin.UserButtonBlock"
  >
    {{ blocked ? 'Unblock User' : 'Block User' }}
  </button>
</div>
