<h1 mat-dialog-title>Create Users</h1>
<div mat-dialog-content>
  Are you sure you want to create these users?
  <br />
  <br />
  Make sure not to reload your browser until all the users have been processed.
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    data-test-id="block-dialog-button"
    [mat-dialog-close]="true"
  >
    Create Users
  </button>
  <button mat-flat-button mat-dialog-close>Cancel</button>
</div>
