export type SlideDefinitionBase = Required<
  Pick<kit.vs.IVideoSlideDefinition, '_id' | 'displayName' | 'accessCategory'>
>;
export type PartialSlideDefinitionBase = Partial<
  Pick<
    kit.vs.IVideoSlideDefinition,
    | 'variables'
    | 'availableForAgencies'
    | 'availableForClients'
    | 'definitionType'
    | 'garbo'
  >
>;

export interface SlideDefinition
  extends SlideDefinitionBase,
    PartialSlideDefinitionBase {}

export const accessTypes = ['client', 'agency'] as const;
export const accessTypeLabels = {
  client: 'Client',
  agency: 'Agency',
};

export interface SlideDefinitionType {
  _id: string;
  name: string;
}
