import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap, shareReplay, switchMap } from 'rxjs/operators';

export enum StatusCodes {
  Default = 0,
  InProgress = 1,
  Success = 2,
  Error = 3,
}

type ReturnedObservable<T> = {
  payload: T;
  status: StatusCodes;
  errorMessage?: string;
  index: number;
};

type QueueObservable<T> = {
  payload: T;
  index: number;
};

type Callback<T> = (payload: T) => Observable<T>;

export class ObservableQueue<T> {
  results$: Observable<ReturnedObservable<T>>;
  private queue = new Subject<QueueObservable<T>>();

  constructor(private cb: Callback<T>) {
    this.results$ = this.queue.pipe(
      mergeMap(
        ({ index, payload }) =>
          this.cb(payload).pipe(
            switchMap((response) =>
              of({
                payload: response,
                status: StatusCodes.Success,
                index,
              })
            ),
            catchError((e) =>
              of({
                payload,
                status: StatusCodes.Error,
                errorMessage: e?.error?.message,
                index,
              })
            )
          ),
        1
      ),
      shareReplay(1)
    );
  }

  push(payload: T, index: number) {
    this.queue.next({ payload, index });
  }
}
