import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpLoadingService } from '@core/services/http-loading.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private loading: HttpLoadingService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.loading.addLoadingRequest(request.url);

    return next.handle(request).pipe(
      catchError((err) => {
        this.loading.removeLoadingRequest(request.url);
        return throwError(err);
      }),
      tap((httpEvent) => {
        if (httpEvent instanceof HttpResponse) {
          this.loading.removeLoadingRequest(request.url);
        }
      })
    );
  }
}
