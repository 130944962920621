import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-freemium-form',
  templateUrl: './freemium-form.component.html',
  styleUrls: ['../../../styles/settings-form.scss'],
})
export class FreemiumFormComponent {
  @Input() form!: FormGroup;
}
