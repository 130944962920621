<div class="container u-marginTxxl u-flex u-flex-align--center">
  <div class="u-marginBm u-marginRxs">
    <mat-label class="o-form-label">Search</mat-label>
    <mat-form-field>
      <input
        [(ngModel)]="filters.search"
        (ngModelChange)="handleSearchChange($event)"
        type="text"
        matInput
        placeholder="Search"
      />
      <button
        *ngIf="filters.search"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <app-icon name="x" class="o-btn__icon u-iconsize--s"></app-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="u-marginBm u-marginRxs">
    <mat-label class="o-form-label">Access Category</mat-label>
    <mat-form-field>
      <mat-select
        [value]="filters.accessCategory"
        (selectionChange)="handleAccessCategoryChange($event)"
      >
        <mat-option value="">All</mat-option>
        <mat-option
          *ngFor="let type of accessCategories | keyvalue"
          value="{{ type.value }}"
        >
          {{ type.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="u-marginBm">
    <mat-checkbox
      [(ngModel)]="filters.garboSceneLocation"
      (ngModelChange)="handleGarboSceneLocationChange($event)"
      >Garbo compatible</mat-checkbox
    >
  </div>

  <div class="u-marginBm u-marginTm align-right">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      Slide definition actions
    </button>
    <mat-menu #menu="matMenu">
      <a
        [routerLink]="['/slide-definitions/add']"
        class="u-flex-align--center"
        mat-button
      >
        <app-icon
          name="users-three"
          weight="fill"
          class="o-btn__icon u-marginRs u-iconsize--m"
        ></app-icon>

        <span>Create slide definition</span>
      </a>
    </mat-menu>
  </div>
</div>

<div class="container u-paddingBxxl u-flex">
  <div class="u-width--full">
    <h3>Slide Definitions</h3>
    <mat-table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let slideDefinition">
          <a [routerLink]="['/slide-definitions', slideDefinition._id]">
            {{ slideDefinition.displayName }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="accessCategory">
        <mat-header-cell *matHeaderCellDef>Access Category</mat-header-cell>
        <mat-cell *matCellDef="let slideDefinition">{{
          slideDefinition.accessCategory
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let slideDefinition">
          <a
            [routerLink]="['/slide-definitions', slideDefinition._id]"
            mat-raised-button
            color="primary"
          >
            Edit
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      [length]="dataSource.totalCount$ | async"
      [pageSize]="(dataSource.filters$ | async)?.pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [pageIndex]="(dataSource.filters$ | async)?.pageIndex"
      (page)="handlePageChange($event)"
    >
    </mat-paginator>
  </div>
</div>
