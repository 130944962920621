<div [formGroup]="form" class="free-trial">
  <div class="u-flex u-flex-align--start">
    <div class="u-flex u-flex-align--center">
      <div class="u-marginRm">
        <mat-label class="o-form-label">Free Trial</mat-label>
        <mat-checkbox formControlName="freeTrial">Enable</mat-checkbox>
      </div>

      <div *ngIf="form.value.freeTrial">
        <mat-form-field
          class="date-form-field"
          appearance="fill"
          floatLabel="always"
        >
          <mat-label>Free Trial End Date</mat-label>
          <input
            matInput
            [matDatepicker]="freeTrialDatePicker"
            formControlName="freeTrialExpirationDate"
          />
          <mat-datepicker #freeTrialDatePicker></mat-datepicker>
          <mat-datepicker-toggle
            matSuffix
            [for]="freeTrialDatePicker"
          ></mat-datepicker-toggle>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
