import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Version } from '../../version';
import {
  APP_VERSION_HEADER,
  SKIP_APP_VERSION_HEADER,
} from '@storykit/constants';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!req.headers.has(SKIP_APP_VERSION_HEADER)) {
      req = req.clone({
        setHeaders: {
          [APP_VERSION_HEADER]: `admin-${Version.number}`,
        },
      });
    } else {
      req = req.clone({ headers: req.headers.delete(SKIP_APP_VERSION_HEADER) });
    }
    return next.handle(req);
  }
}
