export enum ClientNotification {
  Created = 'Client was created',
  NotCreated = 'Client was not created',
  Updated = 'Client was updated',
  NotUpdated = 'Client was not updated',
  Deleted = 'Client was deleted',
  NotDeleted = 'Client was not deleted',
  MultipleUpdated = 'Clients were updated',
  MultipleNotUpdated = 'Clients were not updated',
}
