<div [formGroup]="form">
  <div class="u-marginBm u-flex">
    <div class="u-width--full u-marginRxs">
      <mat-label class="o-form-label">First name</mat-label>
      <mat-form-field>
        <input
          #in
          type="text"
          matInput
          formControlName="firstName"
          placeholder="First name"
          autocomplete="off"
          [skElementId]="elementId.Admin.UserInputFieldFirstName"
        />
      </mat-form-field>
    </div>
    <div class="u-width--full">
      <mat-label class="o-form-label">Last name</mat-label>
      <mat-form-field>
        <input
          #in
          type="text"
          matInput
          formControlName="lastName"
          placeholder="Last name"
          autocomplete="off"
          [skElementId]="elementId.Admin.UserInputFieldLastName"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="u-marginBm u-flex">
    <div class="u-width--full u-marginRxs">
      <mat-label class="o-form-label email-label">
        Email
        <span *ngIf="verified !== undefined" class="verified-container">
          <span
            [ngClass]="{
              verified: verified === true,
              'not-verified': verified === false
            }"
          >
            {{ verified === false ? 'not' : '' }} verified
          </span>
          <button
            *ngIf="verified === false"
            class="new-button-primary-small"
            mat-flat-button
            color="primary"
            (click)="resendEmail()"
          >
            Resend Invite
          </button>
        </span>
      </mat-label>
      <mat-form-field>
        <input
          #in
          type="text"
          matInput
          formControlName="email"
          placeholder="Email"
          autocomplete="off"
          [skElementId]="elementId.Admin.UserInputFieldEmail"
        />
      </mat-form-field>
    </div>
    <div class="u-width--full">
      <mat-label class="o-form-label">Role</mat-label>
      <mat-form-field>
        <mat-select
          formControlName="role"
          [skElementId]="elementId.Admin.UserRoleSelection"
        >
          <mat-option
            *ngFor="let role of userRoles; let i = index"
            value="{{ role }}"
            [skElementId]="elementId.Admin.UserRoleSelectionOption"
            [skElementIdIndex]="i"
          >
            {{ userRoleLabels[role] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="u-marginBm u-flex">
    <div class="u-width--full u-marginRxs">
      <mat-label class="o-form-label">Preferred language</mat-label>
      <mat-form-field>
        <mat-select formControlName="preferredLanguage">
          <mat-option *ngFor="let language of languages" value="{{ language }}">
            {{ languageLabels[language] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-width--full"></div>
  </div>

  <div formGroupName="sso">
    <div class="u-marginBm u-flex">
      <div class="u-width--full">
        <mat-label class="o-form-label">Single Sign-On</mat-label>
        <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>
        <div class="u-color--warning">
          No password email sent but requires SSO configurations by admin.
          Contact Tech if this should be added to an existing user.
        </div>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div class="u-width--full u-marginRxs">
        <mat-label class="o-form-label">Connection Name</mat-label>
        <mat-form-field>
          <input
            #in
            type="text"
            matInput
            formControlName="connectionName"
            placeholder="Name of Enterprise Connection in Auth0"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="u-width--full">
        <mat-label class="o-form-label">Provider</mat-label>
        <mat-form-field>
          <input
            #in
            type="text"
            matInput
            formControlName="provider"
            placeholder="Last name"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="form-validation-errors" *ngIf="form.invalid">
    <span *ngIf="form?.errors?.roleByEmail">
      Role Storykit Administrator can be chosen only for users with emails which
      correspond to such patterns:
      <i>{{ adminEmailPatterns }}</i>
    </span>
  </div>
</div>
