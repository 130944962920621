import { Component } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-loggedin-root',
  template: '<router-outlet></router-outlet>',
})
export class AppLoggedInComponent {
  constructor(private authService: AuthService) {}
}
