import { Injectable } from '@angular/core';
import { UntypedFormArray, FormBuilder, Validators } from '@angular/forms';
import { Access, accessTypes, AccessTypes, roles } from '@models/user';
import { FormValidator } from '@shared/form-validators/form-validator';

export interface AccessFormItem {
  type?: AccessTypes;
  id?: string;
  agencyId?: string;
  role?: kit.TUserAccessRole;
}

@Injectable({
  providedIn: 'root',
})
export class UserAccessForm {
  constructor(private formBuilder: FormBuilder) {}

  setAccessValues(
    access: Access[] = [],
    clients: kit.IClient[]
  ): UntypedFormArray {
    const accessItems = access.map((currentAccess) => {
      if (currentAccess.type === 'client') {
        return this.createClientAccessFormItem(currentAccess, clients);
      }

      return this.createFormGroup(currentAccess);
    });

    return new UntypedFormArray(accessItems);
  }

  createFormGroup({
    type = 'client',
    id,
    agencyId = '',
    role = 'native_editor',
  }: AccessFormItem = {}) {
    return this.formBuilder.group({
      type: [
        type,
        [
          Validators.required,
          FormValidator.notIncludesValidator({ type: true }, accessTypes),
        ],
      ],
      agencyId: [agencyId],
      id: [id, Validators.required],
      role: [
        role,
        [
          Validators.required,
          FormValidator.notIncludesValidator({ role: true }, roles),
        ],
      ],
      agencyFilter: [],
    });
  }

  private createClientAccessFormItem(
    currentAccess: AccessFormItem,
    clients: kit.IClient[]
  ) {
    return this.createFormGroup({
      ...currentAccess,
      agencyId: this.findClientAgencyId(currentAccess.id, clients),
    });
  }

  private findClientAgencyId(clientId = '', clients: kit.IClient[]): string {
    const filteredClient = clients.filter((client) => client._id === clientId);

    return filteredClient[0].agency._id || '';
  }
}
