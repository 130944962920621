import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { environment } from '@environment';
import { FeatureService } from '@storykit/ui-components';
import { ElementId, FEATURE } from '@storykit/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  authenticated = false;
  vsdpAccess = false;

  environment = environment.environment;

  public elementId = ElementId;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private authService: AuthService,
    private featureService: FeatureService,
    public breadcrumbsService: BreadcrumbsService
  ) {
    this.authService.authenticated$.subscribe((authenticated) => {
      this.authenticated = authenticated;
    });

    this.featureService
      .isFeatureEnabledSubject(FEATURE.ADMIN_VSDP)
      .pipe(takeUntil(this.destroy$))
      .subscribe((enabled) => {
        this.vsdpAccess = enabled;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.authService.logout();
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
