<div *ngIf="content" class="input-to-clipboard">
  <mat-label class="o-form-label">{{ label }}</mat-label>
  <div class="u-flex">
    <mat-form-field>
      <input type="text" matInput readonly value="{{ content }}" />
    </mat-form-field>
    <button
      (click)="copyToClipboard()"
      [skElementId]="elementId.Admin.UserPasswordResetLinkField"
    >
      <app-icon name="copy" class="u-flex u-iconsize--m o-btn__icon"></app-icon>
    </button>
  </div>
</div>
