import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
})
export class RedirectComponent implements OnInit {
  errorMsg = '';

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.updateAccessToken();
  }

  private async updateAccessToken() {
    try {
      await this.authService.getRedirectState(window.location);

      this.router.navigate(['users']);
    } catch (err) {
      this.errorMsg = err instanceof Error ? err.message : 'Redirect Error';
    }
  }
}
