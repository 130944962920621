<form class="bulk-form" [formGroup]="form">
  <div class="container container--small u-marginTxxl">
    <div class="u-flex u-flex-justify--end">
      <button
        class="u-marginBm add-user-row"
        mat-flat-button
        (click)="addUserRow()"
        color="primary"
      >
        <app-icon name="plus" class="u-iconsize--xs o-btn__icon"></app-icon>
        Add User
      </button>
    </div>
    <app-user-bulk-table [form]="usersForm"></app-user-bulk-table>
  </div>

  <div
    *ngIf="clientsAgencies$ | async"
    class="container container--small u-marginTl"
  >
    <app-user-access-form
      [form]="accessForm"
      [clientsAgencies$]="clientsAgencies$"
    ></app-user-access-form>
  </div>

  <div class="container container--small u-marginTm">
    <button
      [disabled]="form.invalid"
      class="u-marginTm"
      mat-flat-button
      (click)="openConfirmSaveUsers()"
      color="primary"
    >
      Create Users
    </button>
  </div>
</form>
