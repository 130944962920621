import { Component, OnInit } from '@angular/core';
import { Version } from './version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  ngOnInit() {
    console.log(
      `%cversion: ${Version.number}`,
      'color: cadetblue; font-weight: 900;'
    );
  }
}
