import { Component, Input } from '@angular/core';
import { UserListDataSource } from '@views/user/user-list/user-list.dataSource';
import { User, userRoleLabels } from '@models/user';
import { ElementId } from '@storykit/constants';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: [
    '../../views/partials/table/table.scss',
    './user-list-table.scss',
  ],
})
export class UserListComponent {
  @Input()
  usersDataSource!: UserListDataSource;

  userRoleLabels = userRoleLabels;

  elementId = ElementId;

  displayedColumns = [
    'firstName',
    'lastName',
    'email',
    'role',
    'actions',
    'blocked',
  ];

  assertUser(user: any): User {
    return user;
  }
}
