<div [formGroup]="form" class="access-section">
  <div *ngIf="clientsAgencies$ | async">
    <div class="u-marginBm" *ngIf="count === 0">No access added</div>

    <div
      formArrayName="access"
      *ngFor="let access of accessControls$ | async; let i = index"
    >
      <div
        class="u-flex u-width--full access-form u-marginBm"
        [formGroupName]="i"
      >
        <div class="u-marginRxs">
          <mat-label class="o-form-label">Access type</mat-label>
          <mat-form-field>
            <mat-select
              (selectionChange)="onAccessTypeChange(i)"
              formControlName="type"
              [skElementId]="elementId.Admin.AccessTypeSelection"
            >
              <mat-option
                *ngFor="let accessType of accessTypes; let i = index"
                value="{{ accessType }}"
                [skElementId]="elementId.Admin.AccessTypeSelectionOption"
                [skElementIdIndex]="i"
                >{{ accessTypeLabels[accessType] }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="u-marginRxs">
          <mat-label class="o-form-label">Role</mat-label>
          <mat-form-field>
            <mat-select
              formControlName="role"
              [skElementId]="elementId.Admin.AccessRoleSelection"
            >
              <mat-option
                *ngFor="let accessRole of accessRoles; let i = index"
                value="{{ accessRole }}"
                [skElementId]="elementId.Admin.AccessRoleSelectionOption"
                [skElementIdIndex]="i"
                >{{ roleLabels[accessRole] }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="access.value.type === 'client'" class="u-marginRxs">
          <mat-label class="o-form-label">Agency</mat-label>
          <mat-form-field>
            <mat-select
              #agencySelect
              formControlName="agencyId"
              placeholder="Agency"
              (selectionChange)="onAccessTypeChange(i)"
              [skElementId]="elementId.Admin.AccessAgencySelection"
            >
              <mat-option>
                <ngx-mat-select-search
                  formControlName="agencyFilter"
                  [hideClearSearchButton]="true"
                  placeholderLabel="Agency"
                  noEntriesFoundLabel="No matching agency found"
                ></ngx-mat-select-search>
              </mat-option>
              <ng-container *ngIf="!agencySelect.panelOpen">
                <mat-option
                  *ngIf="getAgency(i); let agency"
                  [value]="agency._id"
                  [skElementId]="elementId.Admin.AccessAgencySelectionOption"
                  [skElementIdIndex]="0"
                >
                  {{ agency.name }} [{{ agency.customerType }}]
                </mat-option>
              </ng-container>
              <cdk-virtual-scroll-viewport
                [itemSize]="42"
                [minBufferPx]="42 * 5 * 2"
                [maxBufferPx]="42 * 5 * 2"
                style="height: {{ 42 * 5 }}px"
              >
                <mat-option
                  *cdkVirtualFor="
                    let agency of getFilteredAgencies(i);
                    let i = index
                  "
                  [value]="agency._id"
                  [skElementId]="elementId.Admin.AccessAgencySelectionOption"
                  [skElementIdIndex]="i"
                >
                  {{ agency.name }} [{{ agency.customerType }}]
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="u-marginRxs"
          *ngIf="access.value.agencyId && access.value.type === 'client'"
        >
          <mat-label class="o-form-label">Client</mat-label>
          <mat-form-field>
            <mat-select
              formControlName="id"
              [skElementId]="elementId.Admin.AccessClientSelection"
            >
              <mat-option
                *ngFor="
                  let client of getClientAgencies$(i) | async;
                  let i = index
                "
                value="{{ client._id }}"
                [skElementId]="elementId.Admin.AccessClientSelectionOption"
                [skElementIdIndex]="i"
                >{{ client.name }}</mat-option
              >
            </mat-select>
            <mat-label
              *ngIf="(clientsAgencies$ | async) === null"
              class="loader"
            >
              <mat-spinner class="prefix" [diameter]="18"></mat-spinner>
            </mat-label>
          </mat-form-field>
        </div>

        <div *ngIf="access.value.type === 'agency'">
          <mat-label class="o-form-label">Agency</mat-label>
          <mat-form-field>
            <mat-select
              #agencySelect
              formControlName="id"
              placeholder="Agency"
              [skElementId]="elementId.Admin.AccessOnlyAgencySelection"
            >
              <mat-option>
                <ngx-mat-select-search
                  formControlName="agencyFilter"
                  [hideClearSearchButton]="true"
                  placeholderLabel="Agency"
                  noEntriesFoundLabel="No matching agency found"
                ></ngx-mat-select-search>
              </mat-option>
              <ng-container *ngIf="!agencySelect.panelOpen">
                <mat-option
                  *ngIf="getAgency(i, 'id'); let agency"
                  [value]="agency._id"
                  [skElementId]="
                    elementId.Admin.AccessOnlyAgencySelectionOption
                  "
                  [skElementIdIndex]="0"
                >
                  {{ agency.name }} [{{ agency.customerType }}]
                </mat-option>
              </ng-container>
              <cdk-virtual-scroll-viewport
                [itemSize]="42"
                [minBufferPx]="42 * 5 * 2"
                [maxBufferPx]="42 * 5 * 2"
                style="height: {{ 42 * 5 }}px"
              >
                <mat-option
                  *cdkVirtualFor="
                    let agency of getFilteredAgencies(i);
                    let i = index
                  "
                  [value]="agency._id"
                  [skElementId]="
                    elementId.Admin.AccessOnlyAgencySelectionOption
                  "
                  [skElementIdIndex]="i"
                >
                  {{ agency.name }} [{{ agency.customerType }}]
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="u-marginLs u-flex u-flex-align--center">
          <button
            mat-icon-button
            (click)="deleteAccessRow(i)"
            [skElementId]="elementId.Admin.AccessDeleteButton"
          >
            <app-icon
              name="trash"
              class="u-color--negative u-iconsize--m o-btn__icon"
            ></app-icon>
          </button>
        </div>
      </div>
    </div>

    <button
      mat-flat-button
      (click)="createAccessRow()"
      [skElementId]="elementId.Admin.AddAccessButton"
      color="primary"
    >
      Add Access
    </button>
  </div>
</div>
