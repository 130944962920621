import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import {
  ObservableQueue,
  StatusCodes,
} from '@core/observable-queue/observable-queue';
import { Access, MutableUser } from '@models/user';
import { MatDialog } from '@angular/material/dialog';
import { BulkSaveConfirmDialogComponent } from './bulk-save-confirm-dialog.component';
import { CwsService } from '@core/services/cws.service';
import { UserForm } from '@shared/user-form/user-form';

interface AccessWithAgency extends Access {
  agencyId: string;
}

type MutableUserWithStatus = MutableUser & {
  status: number;
  errorMessage?: string;
};

@Component({
  selector: 'app-user-bulk-add',
  templateUrl: './user-bulk-add.component.html',
  styleUrls: [
    '../../partials/table/table.scss',
    '../user-list/user-list-table.scss',
    './user-bulk-add.component.scss',
  ],
})
export class UserBulkAddComponent implements OnInit {
  usersForm = this.formBuilder.nonNullable.group({
    users: this.formBuilder.array([this.createFormGroup()]),
  });

  form = new FormGroup({
    users: this.usersForm,
  });

  accessForm = this.formBuilder.group({
    access: this.formBuilder.array([]),
  });

  clientsAgencies$ = this.cwsService.getClientsAgencies();

  constructor(
    private cwsService: CwsService,
    private userForm: UserForm,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  ngOnInit(): void {
    this.breadcrumbsService.set([
      { label: 'Users', link: '/users' },
      { label: 'Bulk Add' },
    ]);
  }

  addUserRow() {
    this.getUserArray().push(this.createFormGroup());
  }

  async openConfirmSaveUsers() {
    const dialogRef = BulkSaveConfirmDialogComponent.open(this.dialog);
    const confirmed = await dialogRef.afterClosed().toPromise();

    if (confirmed) {
      this.saveUsers();
    }
  }

  saveUsers() {
    if (!this.form.valid) {
      return;
    }

    const queue = new ObservableQueue<MutableUser>(this.httpPost());

    queue.results$.subscribe(({ index, status, errorMessage }) => {
      this.setRowStatus(index, status, errorMessage);

      if (status === StatusCodes.Success) {
        this.lockRow(index);
      }
    });

    this.enqueue(queue);
  }

  private getUserArray() {
    return this.usersForm.get('users') as UntypedFormArray;
  }

  private getUserArrayControls() {
    return this.getUserArray().controls;
  }

  private setRowStatus(
    rowIndex: number,
    status: StatusCodes,
    errorMessage?: string
  ) {
    this.getUserArray().at(rowIndex).patchValue({ status, errorMessage });
  }

  private enqueue(queue: ObservableQueue<MutableUser>) {
    this.getUserArrayControls()
      .filter((_, index) => !this.isSaved(index))
      .forEach((formUser, index) => {
        this.setRowStatus(index, StatusCodes.InProgress);

        const result = this.omitUserStatus(formUser.value);
        const access = this.getAccess();

        queue.push({ ...result, access }, index);
      });
  }

  private isSaved(index: number) {
    return this.getUserArray().at(index).value.status === StatusCodes.Success;
  }

  private getAccess(): Access[] {
    const access = this.accessForm.value.access as AccessWithAgency[];
    return access.map(({ id, role, type }) => ({ id, role, type }));
  }

  private lockRow(rowIndex: number) {
    this.getUserArrayControls()[rowIndex].disable();
  }

  private httpPost() {
    return (user: MutableUser) => this.cwsService.createUser(user);
  }

  private omitUserStatus(userWithStatus: MutableUserWithStatus): MutableUser {
    const { status, errorMessage, ...user } = userWithStatus;
    return user;
  }

  private createFormGroup() {
    const formGroup = this.userForm.createFormGroup(undefined, {
      status: [StatusCodes.Default],
      errorMessage: [],
    });

    return formGroup;
  }
}
